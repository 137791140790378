// themeController.js
// console.log('init themeController.js');

// Dynamic theme - magic hours
// Source: https://dev.to/lakshmananarumugam/the-simple-tricks-to-change-your-website-theme-based-on-day-and-night-23l0


// Tabbar message
// document.title = "Olivier Guillard - Freelance Designer &amp; Developer"
// window.onblur = function() {
//     document.title = "Come back soon! 👋"
// };
// window.onfocus = function() {
//     document.title = "Welcome back! 🤙"
// };


// Variables
let modeTogglerDOM = document.querySelector('.mode-toggler');
let timebasedMessageDOM = document.querySelector('.timebased-message');

// https://olivierguillard.dev
const messages = {
    morning: "Hi, Early bird! 👋",
    afternoon: "Good afternoon",
    evening: "Good evening!",
    night: "Zzzz.. Recharging!"
};
// console.log(messages.morning);


// Function to set and save the theme
function setTheme(theme) {
    document.body.setAttribute('data-theme', theme);
    localStorage.setItem('themePreference', theme);
}


// Function to retrieve user's theme preference from local storage
// Function to get and apply the saved theme preference
function getSavedThemePreference() {
    const savedTheme = localStorage.getItem('themePreference');

    if (savedTheme) {
        setTheme(savedTheme); // Use saved preference
    } else {
        setThemeBasedOnTime(); // Use time-based theme if no preference is saved
    }
}


// Functie om het thema in te stellen op basis van het huidige uur
function setThemeBasedOnTime() {
    // Declare constants
    const date = new Date();
    const currentHour = date.getHours();

    // getHours() method returns the hour (from 0 to 23) of the specified date and time.
    // Daytime = 0 - 11 && Nighttime = 12 - 23

    // The dark theme load early morning and night
    // The light theme load morning and evening
    if (currentHour >= 19 || currentHour <= 6) {
        setTheme('dark');
    } else {
        setTheme('light');
    }
}


// Function to set the status message and icon
function setStatusMessage(message, timeOfDay) {
    if (timebasedMessageDOM && modeTogglerDOM) {
        timebasedMessageDOM.innerHTML = message;
        modeTogglerDOM.classList.remove('--day', '--night');
        modeTogglerDOM.classList.add(timeOfDay);
        modeTogglerDOM.querySelector('.mode-message').innerHTML = timeOfDay === '--day' ? 'F/5.6' : 'F/22';
    }
}


// Set time-based message
function setMessageBasedOnTime() {
    // Declare constants
    const date = new Date();
    const currentHour = date.getHours();
    const currentMinutes = String(date.getMinutes()).padStart(2, '0');

    // Bepaal het bericht op basis van het huidige uur
    if (timebasedMessageDOM && modeTogglerDOM) {
        // Morning
        if (currentHour >= 6 && currentHour < 12) {
            setStatusMessage(`${currentHour}:${currentMinutes} - ${messages.morning}`, '--day');
        }

        // Afternoon
        else if (currentHour >= 12 && currentHour < 18) {
            setStatusMessage(`${currentHour}:${currentMinutes} - ${messages.afternoon}`, '--day');
        }

        // Evening
        else if (currentHour >= 18 && currentHour < 23) {
            setStatusMessage(`${currentHour}:${currentMinutes} - ${messages.evening}`, '--night');
        }

        // Night
        else {
            setStatusMessage(`${currentHour}:${currentMinutes} - ${messages.night}`, '--night');
        }
    }
}


// Update the time and theme every minute
function updateCurrentTime() {
    const date = new Date();
    const secondsUntilNextMinute = 60 - date.getSeconds();

    // Wait for the remaining seconds until the next minute to pass
    setTimeout(function () {
        // Run setMessageBasedOnTime after the remaining seconds have elapsed
        setMessageBasedOnTime();

        // Update the time every minute
        setInterval(setMessageBasedOnTime, 60000);
    }, secondsUntilNextMinute * 1000); // Convert seconds to milliseconds
}


// Function to toggle between light and dark mode
function toggleDarkLightMode() {
    const body = document.body;
    const currentTheme = body.getAttribute('data-theme'); // get current theme from 'data-theme' attribute
    const newTheme = currentTheme === 'light' ? 'dark' : 'light'; // Determine the new theme value based on the current value

    // Set the new theme
    setTheme(newTheme);

    // Update the F value based on the theme
    const modeMessageElement = modeTogglerDOM.querySelector('.mode-message');
    if (modeMessageElement) {
        modeMessageElement.innerHTML = newTheme === 'light' ? 'F/5.6' : 'F/22'; // Change F-value based on the theme
    }
}

// Function to switch the dark and light themes
function initializeThemeToggle() {
    if (modeTogglerDOM) {
        modeTogglerDOM.addEventListener('click', toggleDarkLightMode);
    }
}

// Call functions on page load
getSavedThemePreference();
setMessageBasedOnTime();
updateCurrentTime();
initializeThemeToggle();
