// scrollTriggerController.js
console.log('observerController init');

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Register ScrollTrigger
gsap.registerPlugin(ScrollTrigger);

export function createScrollTrigger(target, start, end, scrub, animation, trigger = null, from = false) {
	// Check if the target is a DOM element or a selector string
	let elDOM = (target instanceof HTMLElement || target instanceof NodeList) ? target : document.querySelector(target);
	if (!elDOM) {
		// console.error(`Target element ${target} not found.`);
		return; // Stop the function if the target element doesn't exist
	}

	// Check if the trigger is provided and whether it is a DOM element or a selector string
	const triggerDOM = trigger
		? (trigger instanceof HTMLElement ? trigger : document.querySelector(trigger))
		: elDOM;

	if (trigger && !triggerDOM) {
		// console.error(`Trigger element ${trigger} not found.`);
		return; // Stop the function if the trigger element doesn't exist
	}

	// Check if GSAP and ScrollTrigger are loaded
	if (typeof gsap !== "undefined" && typeof ScrollTrigger !== "undefined") {
		const animationParams = {
			scrollTrigger: {
				trigger: triggerDOM, // Use the trigger element (or the target if no trigger is provided)
				start: start,        // Where the animation starts (e.g. "top bottom")
				end: end,            // Where the animation ends (e.g. "bottom top")
				scrub: scrub !== undefined ? scrub : false, // Make the animation scrubbable
				// markers: true,      // Set this to 'true' if you want to see the markers
				// scale: 1.25,
				// duration: 1,
			},
			...animation // Add the animation parameters (e.g. opacity, transform)
		};

		// Use gsap.from() if the 'from' parameter is true, otherwise use gsap.to()
		if (from) {
			gsap.from(elDOM, animationParams);
		} else {
			gsap.to(elDOM, animationParams);
		}
	} else {
		console.error("GSAP and ScrollTrigger must be loaded.");
	}
}
