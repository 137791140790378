// horizontalScrollController.js
// Inspiration https://axelvanhessche.com/works/summer-market/ 
// based on https://youtu.be/cvvFbwVlVrM

// import scripts
import { gsap } from "gsap";
import { observeIntersection } from './observerController.js';

// script
let target = 0;
let current = 0;
let ease = 0.075;
const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
};

// Declare variables
const slider = document.querySelector('.slider');
const sliderWrapper = slider?.querySelector('.filmframes');
const slides = slider?.querySelectorAll('.frame');

// Checking if all required elements exist before proceeding
if (slider && sliderWrapper && slides.length > 0) {
    // Proceed only if the elements exist
    let maxScroll = slider.offsetWidth - window.innerWidth;

    // Linear interpolation function
    function lerp(start, end, factor) {
        return start + (end - start) * factor;
    }

    // Animate the slide coming into view
    function animateSlideIn(slide, image) {
        gsap.to(slide, { opacity: 1 });

        // scale image in
        gsap.to(image, {
            duration: 1,
            scale: 1,
            ease: "power2.inOut",
        });
    }

    // Animate the slide going out of view
    function animateSlideOut(slide, image) {
        gsap.to(slide, { opacity: 0.25 }); // set opacity of slide
        gsap.set(image, { scale: 1.05 }); // set scale on images
    }

    // Handle intersection changes for slides
    function handleIntersection(slide, isIntersecting) {
        let image = slide.querySelector('img');

        // Check if the current opacity is already 1
        if (slide.style.opacity === '1') {

            // If the right edge of the slide is beyond the visible window width, reduce its opacity to 0.25
            if (slide.getBoundingClientRect().right > window.innerWidth) {
                gsap.to(slide, { opacity: 0.25 });
            }

            return;
        }

        // If the element is intersecting and the opacity is not already 1, set the opacity to 1
        if (isIntersecting) {
            // console.log('visible');
            animateSlideIn(slide, image);
        } else {
            // console.log('invisible');
            animateSlideOut(slide, image);
        }
    }

    observeIntersection(slides, options, handleIntersection);

    // Update function for animation
    function update() {
        current = lerp(current, target, ease);
        gsap.set(slider, { x: -current });

        requestAnimationFrame(update);
    }

    // resize listener
    window.addEventListener('resize', () => {
        console.log('resize slider', maxScroll);
        maxScroll = slider.offsetWidth - window.innerWidth;
    });

    // window.addEventListener('wheel', (e) => {
    //     target += e.deltaY;
    //     target = Math.max(0, target);
    //     target = Math.min(maxScroll, target);
    // });

    update();
}