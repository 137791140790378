// videoController.js

// Variables
const videoDOM = document.querySelector('.videoplayer video');

if (videoDOM) {
	// console.log(videoDOM);
	videoDOM.addEventListener('click', playVideo);
}

function playVideo() {
	event.preventDefault(); // Voorkomt onbedoelde reloads op iOS
	var parentDOM = this.closest('.videoplayer-wrapper');

	if (parentDOM) {
		// Stop andere video's
		document.querySelectorAll('.videoplayer video').forEach(video => {
			if (video !== this) {
				video.pause();
				video.closest('.videoplayer-wrapper')?.classList.remove('is-playing');
			}
		});

		// Toggle play/pause
		parentDOM.classList.toggle('is-playing');
		parentDOM.classList.contains('is-playing') ? this.play() : this.pause();
	}
}


// PREVIEW VIDEO ON HOVER
const mediaCards = document.querySelectorAll('.media--video'); // Select all media cards with the class "media--video"

// Check if there are any mediaCards
if (mediaCards.length > 0) {
	// Loop through each media card
	mediaCards.forEach(function (card) {
		// Select the video within this specific card
		const mediaCardVideo = card.querySelector('video');

		// Check if there is a video element inside the card
		if (mediaCardVideo) {
			// Function for mouse enter
			function onMouseEnter() {
				mediaCardVideo.play(); // Start playing the video
			}

			// Function for mouse leave
			function onMouseLeave() {
				mediaCardVideo.pause(); // Pause the video
				mediaCardVideo.currentTime = 0; // Reset the video to the beginning
			}

			// Add event listeners to the card
			card.addEventListener('mouseenter', onMouseEnter);
			card.addEventListener('mouseleave', onMouseLeave);
		}
	});
}
