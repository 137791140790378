// animationController.js
console.log('animationController init');

// Import scripts
import { gsap } from "gsap";
import { observeIntersection } from './observerController.js';
import { createScrollTrigger } from "./scrollTriggerController.js";
import { extractLinesFromElement } from "./splitTextController.js";


// Variables
const DURATION_SHORT = 0.5;
const DURATION_MEDIUM = 1; // Before change it was .75
const DURATION_LONG = 1.5;
const STAGGER = 0.05;
const EASE_NONE = "none";
const EASE_OUT_1 = "power1.out";
const EASE_OUT_2 = "power2.out";
const TRIGGER_START_30 = "top 30%"; 	// when the top of the trigger hits the top of the viewport
const TRIGGER_START_80 = "top 80%";
const TRIGGER_END_TOP = "bottom top"; 	// end after scrolling 500px beyond the start
const TRIGGER_END_500 = "+=500";


// Animation: Stories image sequence Pin
// Selecteer het canvas en stel de context in
// Required to call before other ScrollTriggers
const canvasImageSequence = document.querySelector(".image-sequence");
if (canvasImageSequence) {
	const context = canvasImageSequence.getContext("2d");

	// Configuratie van de image sequence
	const frameCount = 12; // Aantal frames in de sequence
	const currentFrame = (index) => `images/image-imagery-${index.toString().padStart(1, '0')}.jpg`;


	// Create an array for the images
	const images = [];
	const imageSequence = { frame: 0 };

	// Set dimensions of the canvasImageSequence
	canvasImageSequence.width = window.innerWidth;
	canvasImageSequence.height = window.innerHeight;


	// Load all images
	for (let i = 0; i < frameCount; i++) {
		const img = new Image();
		img.src = currentFrame(i + 1);
		images.push(img);
	}

	// Functie om een frame te tekenen
	const render = () => {
		const img = images[imageSequence.frame];
		const adjustedCanvasHeight = canvasImageSequence.height + 120; // Canvashoogte + 120px voor parallax
		const canvasAspectRatio = canvasImageSequence.width / adjustedCanvasHeight;
		const imgAspectRatio = img.width / img.height;

		let drawWidth, drawHeight, offsetX, offsetY;

		// Bepaal hoe de afbeelding moet worden getekend
		if (imgAspectRatio > canvasAspectRatio) {
			// Afbeelding is breder dan het canvas
			drawWidth = canvasImageSequence.height * imgAspectRatio;
			drawHeight = canvasImageSequence.height;
			offsetX = -(drawWidth - canvasImageSequence.width) / 2;
			offsetY = 0;
		} else {
			// Afbeelding is hoger dan het canvas
			drawWidth = canvasImageSequence.width;
			drawHeight = canvasImageSequence.width / imgAspectRatio;
			offsetX = 0;
			offsetY = -(drawHeight - canvasImageSequence.height) / 2;
		}

		// Wis het canvas en teken de afbeelding met achtergrond-cover effect
		context.clearRect(0, 0, canvasImageSequence.width, canvasImageSequence.height);
		context.drawImage(img, offsetX, offsetY, drawWidth, drawHeight);
	};


	// Zorg dat afbeeldingen worden geladen
	images[0].onload = render;

	// GSAP animatie met ScrollTrigger
	gsap.to(imageSequence, {
		frame: frameCount - 1,
		snap: "frame",
		ease: EASE_NONE,
		scrollTrigger: {
			trigger: '.stories', // Pinnen binnen de "stories"-sectie
			start: "top top",
			end: `+=${frameCount * 250}`, // Stel een langere scroll-afstand in
			scrub: true,
			pin: true, // Pinnen de "stories"-sectie
		},
		onUpdate: render
	});

	// Pas canvas grootte aan bij resizing
	window.addEventListener("resize", () => {
		canvasImageSequence.width = window.innerWidth;
		canvasImageSequence.height = window.innerHeight;
		render();
	});

}


// Animation: Parallax hero
const heroSection = document.querySelector('.hero');
const heroImage = heroSection?.querySelector('.hero-image img');
// const heroImage = heroImageContainer?.querySelector('img');

createScrollTrigger(
	heroImage,
	TRIGGER_START_30,
	TRIGGER_END_TOP,
	DURATION_SHORT,
	{
		y: -120
	},
	heroSection,
);

// Setup parallax sections
const sectionParallax = document.querySelectorAll(".parallax");
sectionParallax.forEach(section => {
	const image = section.querySelector(".parallax-container .parallax-image");
	const offset = parseInt(image?.dataset.offset || 120);
	const trigger = section;

	if (image) {
		createScrollTrigger(
			image,
			"top bottom",
			TRIGGER_END_TOP,
			DURATION_SHORT,
			{
				y: -offset
			},
			trigger,
		);
	}
});



// Animate service films
const servicesRows = document.querySelectorAll(".services .row"); // select all rows

// Loop door elke row en voeg een animatie toe
servicesRows.forEach((row, index) => {
	// Bepaal de rotatiewaarde: 4 graden of -4 graden afhankelijk van de index
	const rotation = index % 2 === 0 ? 4 : -4;
	const image = row.querySelector(".gallery-card--media");

	// Check if the image element exists
	if (!image) {
		// If the image does not exist, skip this iteration
		// console.error(`No image found for row at index ${index}`);
		return;
	}

	// Beginpositie (voor de animatie)
	gsap.set(image, {
		y: 72, // Start 72px lager
		opacity: 0, // Begin volledig transparant
	});

	// Maak de animatie
	gsap.to(image, {
		scrollTrigger: {
			trigger: row, // De row activeert de animatie
			start: "top center", // Start animatie als de top van de row 75% van het venster bereikt
			toggleActions: "play none none none", // Speel de animatie alleen af
		},
		rotate: rotation, // Roteer naar de berekende waarde
		y: 0, // Animeer naar de oorspronkelijke positie
		opacity: 1, // Fade in naar volledig zichtbaar
		duration: DURATION_MEDIUM, // Duur van de animatie
		ease: EASE_OUT_2, // Soepelheid van de animatie
	});

});


// HOME: Spinner icon
const spinner = document.querySelector('.spinner');
if (spinner) {
	window.addEventListener('scroll', () => {
		let scrollPosY = window.scrollY;
		spinner.style.transform = 'rotate(' + (scrollPosY / 14) + 'deg)';
	});
}

// Deactiveer scroll van de pagina
// const pageHome = document.getElementById("home");
// pageHome && (document.body.style.overflow = 'hidden');


// search for data object animation-in or something to determine if the section should be animated in
// observe the section
// do animation and add animated-in class


// add load in animation for sections
// intersecting observer
var options = {
	threshold: 0.5
};

const sections = document.querySelectorAll('section');

// sections.forEach((section) => {
// 	// Check if the section has the 'no-animate' class
// 	if (!section.classList.contains('no-animate') && !section.classList.contains('hero') && !section.classList.contains('footer')) {
// 		const rows = section.querySelectorAll('.row');

// 		rows.forEach((row) => {
// 			const childs = Array.from(row.children);
// 			gsap.set(childs, {
// 				opacity: 0,
// 				yPercent: 16,
// 			});
// 			observeIntersection(childs, options, animateInSections);
// 		});
// 	}
// });

function animateInSections(element, isIntersecting) {
	// console.log('do something here');

	if (isIntersecting) {
		gsap.to(element, {
			opacity: 1,
			yPercent: 0,
			duration: 0.75
		});
		element.setAttribute('is-animated', '');
	}
}


// DOM: FOOTER
// (WORKING CODE FOR ONLY DEFINED HTML IMAGES)
if (document.querySelector('.footer-card-media')) {
	// animate in images with scaling
	gsap.set('.footer-card-media', {
		opacity: 0,
		scale: 0,
		yPercent: 50,
	});

	gsap.to('.footer-card-media', {
		scrollTrigger: {
			trigger: '#site-footer',
			start: 'top center',
			// markers: true, 
		},
		duration: 1,
		opacity: 1,
		scale: 1,
		yPercent: 0,
		ease: "Power2.easeInOut",
		stagger: 0.25,
		// delay: DURATION_MEDIUM,
	});
}

// Sunrise animation
if (document.querySelector('#site-footer')) {
	gsap.set("#site-footer", {
		"--footer-before-opacity": "0",
	});
	gsap.to("#site-footer", {
		scrollTrigger: {
			trigger: "#site-footer", // Trigger op de sectie .about
			start: 'top center',
		},
		"--footer-before-opacity": "1",
		duration: 4,
		delay: 1,
	});
}


// Animate line about section
// Get the id of the <path> element and the length of <path>
let signatureLine = document.getElementById("line");
if (signatureLine) {
	let length = line.getTotalLength();

	// The start position of the drawing
	signatureLine.style.strokeDasharray = length;

	// Hide the line by offsetting dash. Remove this line to show the line before scroll draw
	signatureLine.style.strokeDashoffset = length;

	// Create GSAP timeline for the animation
	let tlLine = gsap.timeline({
		scrollTrigger: {
			trigger: ".about", // Trigger op de sectie .about
			start: "top center", // Start de animatie wanneer het midden van de trigger in het zicht komt
			end: "bottom center", // Eindig de animatie wanneer het onderste deel van de trigger in het zicht komt
		}
	});

	// Voeg animatie toe aan de timeline
	tlLine.to(signatureLine.style, { strokeDashoffset: 0, duration: 6 });
}



// Photography film (album) slider animation
// Animate in the different frames using stagger
const optionsFilm = {
	root: null,
	rootMargin: '0px',
	threshold: 0.5
};

// GSAP animatiefunctie voor de frames
function animateFrames(frames) {
	gsap.set(frames, { opacity: 0 });
	gsap.to(frames, {
		opacity: 1,
		stagger: 0.1,
		ease: "power2.out"
	});
}

// Call the global observeIntersection function
const films = document.querySelectorAll('.films');

// Intersection Observer callback that is invoked from the observeIntersection function
function handleIntersection(target, isIntersecting) {
	if (isIntersecting && target.classList.contains('films')) {
		const frames = target.querySelectorAll('.filmframes .frame');
		animateFrames(frames); // Start de animatie zodra .films in beeld komt
	}
}

observeIntersection(films, optionsFilm, handleIntersection);


// Animate mission section
const missionSection = document.querySelector('.mission');
const missionMedia = missionSection?.querySelectorAll('.gallery-card'); // Select both elements

// GSAP animation for the gallery media
const missionAnimation = {
	top: '50%',    // Place them in the vertical center
	left: '50%',   // Place them in the horizontal center
	transform: 'translate(-50%, -50%) rotate(0deg)', // Start without rotation
	opacity: 0,
	stagger: 0.25, // Add 0.3 seconds delay between animations
	duration: DURATION_LONG, // Duration of each animation
	ease: 'power3.out', // Smooth transition easing
};

// Create scroll trigger for the first and second gallery item
createScrollTrigger(missionMedia, TRIGGER_START_30, TRIGGER_END_TOP, false, missionAnimation, missionSection, true);
// createScrollTrigger(missionMedia[1], TRIGGER_START_30, TRIGGER_END_TOP, false, missionAnimation, missionSection, true);


// Selecteer alle woorden in de headline
// Selecteer alle woorden in de headline
let words = document.querySelectorAll(".headline-word");

// Functie om elk woord in individuele karakters te splitsen
function splitWordsIntoChars() {
	words.forEach(word => {
		let text = word.textContent;
		// Splits de tekst in karakters en voeg een span toe rond elk karakter
		word.innerHTML = text.split("").map(char => `<span class="char">${char}</span>`).join("");
	});
}

// Functie voor het animeren van de letters met GSAP
function animateHeadline() {
	words.forEach((word, index) => {
		let chars = word.querySelectorAll(".char");  // Selecteer alle karakters binnen het woord

		// Voer de animatie voor de letters uit met stagger, met behulp van fromTo()
		gsap.fromTo(
			word, // Target is de array van karakters binnen elk woord
			{ opacity: 0, y: "3rem" }, // Beginwaarden voor opacity en y-positie
			{
				opacity: 1,  // Eindwaarden voor opacity
				y: "0em",    // Eindwaarden voor y-positie (terug naar de oorspronkelijke positie)
				duration: 0.5,
				stagger: { amount: 0.3 }, // Vertraging tussen de karakters
				delay: index * 0.3, // Vertraging per woord
				ease: "power3.out" // Vloeiende overgang
			}
		);
	});
}

// Splits de woorden in karakters en start de animatie
// splitWordsIntoChars();
animateHeadline();


// Selecteer alle elementen met de class .gallery-card
const galleryCards = document.querySelectorAll('.gallery-card');

// Intersection Observer callback that is invoked from the observeIntersection function
function galleryCardhandleIntersection(target, isIntersecting) {
	if (target.hasAttribute('data-animate') && isIntersecting) {
		target.classList.add('focus');
	}
}

observeIntersection(galleryCards, optionsFilm, galleryCardhandleIntersection);



// Marquee text scroller
// let currentScroll = 0;
// let isScrollingDown = true;
let marqueeItem = document.querySelectorAll('.marquee-item');

let tween = gsap.to(marqueeItem, {
	xPercent: -100,
	repeat: -1,
	duration: 20,
	ease: 'linear',
}).totalProgress(0.5);



// Photography typography hover
// Based on https://codepen.io/dev_loop/pen/OJWNabR
// Create a GSAP timeline for sequencing animations.
const sectionPhotography = document.querySelector(".photography"); // Select all list items.
const listItems = document.querySelectorAll(".list-item"); // Select all list items.

const tlText = gsap.timeline({
	scrollTrigger: {
		trigger: sectionPhotography, // Trigger de animatie wanneer de fotografiesectie in beeld komt.
		start: "top center", // Start de animatie wanneer de bovenkant van de fotografiesectie het midden van het scherm bereikt.
		end: "bottom center", // Eindig de animatie wanneer de onderkant van de fotografiesectie het midden van het scherm bereikt.
		toggleActions: "play none none none", // Speel de animatie af wanneer de trigger in beeld komt, maar niet wanneer hij uit beeld gaat.
	}
});

// Animate text on load
tlText.set(".menu", { autoAlpha: 1 }); // Set the initial opacity of the menu to 1 (visible).
tlText.from(".list-item-innertext", {
	// Animate the text elements within list items.
	delay: 0.25, // Delay the animation by 1 second.
	duration: 0.85, // Set the duration of the animation to 0.85 seconds.
	yPercent: 120, // Move the text elements vertically by 120% of their height.
	stagger: 0.095, // Stagger the animation of each text element by 0.095 seconds.
	skewY: gsap.utils.wrap([-8, 8]), // Skew the text elements within a range of -8 to 8 degrees.
	ease: "expo.out", // Apply the "expo.out" easing function for smoother animation.
});
tlText.set(".menu", { pointerEvents: "all" }); // Enable pointer events on the menu after animation completion.

gsap.defaults({
	duration: 1, // Set the default duration for animations to 0.55 seconds.
	ease: "expo.out" // Set the default easing function to "expo.out".
});

function onMouseEnter(imageWrapper) {
	// console.log('move');

	// Define function to execute when mouse enters the list item.
	gsap.set(imageWrapper, {
		scale: 0.8, // Scale down the imageWrapper to 80% of its original size.
		yPercent: 50, // Move the imageWrapper vertically by 50% of its height.
		rotation: -16, // Rotate the imageWrapper by -16 degrees.
	});
	gsap.to(imageWrapper, {
		opacity: 1, // Make the imageWrapper media fully visible.
		scale: 1, // Scale the imageWrapper back to its original size.
		yPercent: 0, // Reset the vertical position of the imageWrapper.
		rotation: 0, // Reset the rotation of the imageWrapper.
	});
}

function onMouseLeave(imageWrapper) {
	// console.log('leave');

	// Define function to execute when mouse leaves the list item.
	gsap.to(imageWrapper, {
		opacity: 0, // Make the imageWrapper media invisible.
		yPercent: -50, // Move the imageWrapper vertically up by 50% of its height.
		scale: 0.8, // Scale down the imageWrapper to 80% of its original size.
		rotation: -16, // Rotate the imageWrapper by -16 degrees.
	});
}

function onMouseMove(itemBounds, imageWrapper, imageWrapperBounds, xTo, yTo) {
	return function (e) {
		let yOffset = itemBounds.top / imageWrapperBounds.height;
		yOffset = gsap.utils.mapRange(0, 1.5, -150, 150, yOffset);

		const rect = e.target.getBoundingClientRect();
		const x = Math.abs(e.clientX - rect.left); // x position within the element.
		const y = Math.abs(e.clientY - rect.top) - imageWrapperBounds.height / 2;  // y position within the element.

		xTo(x);
		yTo(y);
	};
}

// Wait until onload is ready
// Fix for Safari - getBoundingClientRect
window.onload = function () {

	if (sectionPhotography) {

		listItems.forEach(function (item) {
			let itemBounds = item.getBoundingClientRect();
			const imageWrapper = item.querySelector('.card-media');
			// console.log(imageWrapper);
			const imageWrapperBounds = imageWrapper.getBoundingClientRect(); // Get the bounding rectangle of the imageWrapper media.

			// Set initial properties of the imageWrapper media.
			gsap.set(imageWrapper, { xPercent: -50, yPercent: -50 });

			// Create quickTo functions outside of the event handler to avoid redundant creation.
			const xTo = gsap.quickTo(imageWrapper, "x", { duration: 1.25 });
			const yTo = gsap.quickTo(imageWrapper, "y", { duration: 1.25 });

			// Add event listeners using the pre-defined functions.
			item.addEventListener("mouseenter", function () {
				onMouseEnter(imageWrapper);
			});

			item.addEventListener("mouseleave", function () {
				onMouseLeave(imageWrapper);
			});

			item.addEventListener("mousemove", onMouseMove(itemBounds, imageWrapper, imageWrapperBounds, xTo, yTo));

			window.addEventListener("resize", function () {
				// Update the bounding rectangle on window resize.
				item.getBoundingClientRect();
			});
		});

	}
}


// Animate-in elements with data attribute
window.addEventListener("load", function () {
	var headlineElements = document.querySelectorAll('[animate-headline]');
	var textElements = document.querySelectorAll('[animate-text]');
	var elements = document.querySelectorAll('[animate-opacity]');

	if (headlineElements.length) {
		// Process each element that contains a text node
		headlineElements.forEach(function (element) {
			extractLinesFromElement(element);
			animateTextOnScroll(element);
		});
	}

	if (textElements.length) {
		textElements.forEach(function (element) {
			animateTextOpacity(element);
		});
	}

	if (elements.length) {
		gsap.from(elements, {
			opacity: 0,
			yPercent: 12,
			stagger: .175,
			duration: DURATION_MEDIUM,  // Duration of the animation
			ease: EASE_OUT_1,
			scrollTrigger: {
				trigger: elements[0],  // Ensures each element triggers separately
				start: TRIGGER_START_80,
				end: TRIGGER_END_500, // Ends 500px after the trigger point
				scrub: 1,    // Enables scrubbing for smooth animation
				once: true,    // Ensures the animation is triggered only once
			}
		});
	}
});

// Adds the animation to each element when the user scrolls
function animateTextOnScroll(element) {
	gsap.from(element.querySelectorAll('.line .word'), {
		opacity: 0,
		y: 20,
		ease: EASE_OUT_1,
		stagger: STAGGER, // Delay between animations of words
		duration: DURATION_MEDIUM,   // Duration of the animation
		scrollTrigger: {
			trigger: element,  // Ensures each element triggers separately
			start: TRIGGER_START_80,
			end: TRIGGER_END_500, // Ends 500px after the trigger point
			scrub: 1,    // Enables scrubbing for smooth animation
			once: true,    // Ensures the animation is triggered only once
		},
	});
}

// Adds the opacity animation for elements with the 'animate-text' attribute
function animateTextOpacity(element) {
	gsap.from(element, {
		opacity: 0,
		duration: DURATION_MEDIUM,  // Duration of the animation
		ease: EASE_OUT_1,
		scrollTrigger: {
			trigger: element,  // Ensures each element triggers separately
			start: TRIGGER_START_80,
			end: TRIGGER_END_500, // Ends 500px after the trigger point
			scrub: 1,    // Enables scrubbing for smooth animation
			once: true,    // Ensures the animation is triggered only once
		}
	});
}