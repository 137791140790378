// observerController.js
console.log('observerController init');

export function observeIntersection(elements, options, callback) {
    // Intersection Observer API 
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            callback(entry.target, entry.isIntersecting);
        });
    }, options);

    // Iterate through each element in the 'elements' array 
    // and start observing each element for intersection with the viewport
    elements.forEach((element) => {
        observer.observe(element);
    });
}
