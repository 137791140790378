// splitTextController.js
console.log('splitTextController init');

// Extracts lines while keeping inline elements inside words
export function extractLinesFromElement(element) {
    var range = document.createRange();
    var lines = [];
    var currentLine = [];
    var lastTop = null;

    function processNode(node, parentWrapper = null) {
        if (node.nodeType === Node.TEXT_NODE) {
            let words = node.textContent.split(/\s+/).map((word, index, array) => {
                // Als het niet het laatste woord is, voeg een spatie toe
                if (index < array.length - 1) {
                    return word + " ";
                }
                return word; // Voeg geen spatie toe voor het laatste woord
            });

            words.forEach(word => {
                // console.log(word);
                let wordSpan = document.createElement('span');
                wordSpan.classList.add('word');

                let contentSpan = document.createElement('span'); // Inner span to hold text
                contentSpan.textContent = word;
                wordSpan.appendChild(contentSpan);

                if (parentWrapper) {
                    let wrapper = document.createElement(parentWrapper.tagName.toLowerCase());
                    [...parentWrapper.attributes].forEach(attr => wrapper.setAttribute(attr.name, attr.value));
                    wrapper.appendChild(contentSpan);
                    wordSpan.appendChild(wrapper); // Preserve inline element inside the word
                }

                document.body.appendChild(wordSpan);
                let rect = wordSpan.getBoundingClientRect();
                document.body.removeChild(wordSpan);

                if (lastTop === null || rect.top === lastTop) {
                    currentLine.push(wordSpan);
                } else {
                    lines.push(currentLine);
                    currentLine = [wordSpan];
                }
                lastTop = rect.top;
            });
        } else if (node.nodeType === Node.ELEMENT_NODE && isInline(node)) {
            let wrapper = document.createElement(node.tagName.toLowerCase());
            [...node.attributes].forEach(attr => wrapper.setAttribute(attr.name, attr.value));

            node.childNodes.forEach(child => processNode(child, wrapper));
        }
    }

    function isInline(element) {
        return window.getComputedStyle(element).display === "inline";
    }

    [...element.childNodes].forEach(node => processNode(node));

    if (currentLine.length) lines.push(currentLine);

    element.textContent = '';
    lines.forEach(line => {
        let lineSpan = document.createElement('span');
        lineSpan.classList.add('line');
        line.forEach(wordSpan => {
            // console.log('wordSpan', wordSpan);
            lineSpan.appendChild(wordSpan)
        });
        element.appendChild(lineSpan);
        // Optionally add a line-break for visual separation
        element.appendChild(document.createElement('br'));
    });

    // Log lines for debugging.
    // logLines(lines);

}

// Normalizes whitespace to match the rendered whitespace
function collapseWhiteSpace(value) {
    return value.trim().replace(/\s+/g, " ");
}

// Logs the found lines to the console
function logLines(lines) {
    console.group('Rendered Lines of Text');
    lines.forEach(function (line, i) {
        console.log(i, line);
    });
    console.groupEnd();
}

// Adds the lines and words as <span> elements to the original element
function addLinesWithWordsToElement(lines, element) {
    // Clear the original element's content
    element.textContent = '';

    lines.forEach(function (line) {
        var lineSpan = document.createElement('span');
        lineSpan.classList.add('line');

        // Split the line into words and wrap each word in a span
        var words = line.split(' ');
        words.forEach(function (word) {
            var wordSpan = document.createElement('span');
            wordSpan.classList.add('word');
            wordSpan.textContent = word + ' '; // Add space after each word
            lineSpan.appendChild(wordSpan);
        });

        element.appendChild(lineSpan);

        // Optionally add a line-break for visual separation
        element.appendChild(document.createElement('br'));
    });
}
