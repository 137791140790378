// index.js
console.log('main init');

// Import stylesheets and libraries
import './scss/main.scss';

// Import scripts
import './scripts/cursorController.js';
import './scripts/themeController.js';
import './scripts/canvasController.js';
import './scripts/animationController.js';
import './scripts/videoController.js';
import './scripts/splitTextController.js';
import './scripts/horizontalScrollController.js';


// Import scripts
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";

import Lenis from '@studio-freight/lenis';

// Register ScrollTrigger
gsap.registerPlugin(ScrollTrigger, Draggable);


// https://www.edoardolunardi.dev/
// https://axelvanhessche.com/works/summer-market/
// Add lenis smooth scroll
let lenis;
const scrollContainer = document.querySelector(".slider");

if (scrollContainer) {

	lenis = new Lenis({
		content: scrollContainer,
		// wrapper: document.querySelector(".app"),
		// wrapper: scrollContainer,

		lerp: .1,
		wheelMultiplier: 0.7,
		smoothWheel: true,
		orientation: 'horizontal',
		easing: function (t) {
			return (t === 1 ? 1 : 1 - Math.pow(2, -10 * t))
		}
		// orientation: isMobile ? 'vertical' : 'horizontal'
	});

} else {
	lenis = new Lenis();
}

lenis.on('scroll', (e) => {
	// console.log(e);
	// console.log({scroll,limit});
});

function raf(time) {
	lenis.raf(time);
	requestAnimationFrame(raf);
}
requestAnimationFrame(raf);

// Set copyright year dynamic
const copyright = document.getElementById("copyright--year");
if (copyright) {
	copyright.textContent = new Date().getFullYear();
}

// CASE DETAIL: Parallax image case hero
const caseHero = document.querySelector('.case-hero');
if (caseHero) {
	const caseHeroImage = caseHero.querySelector('img');
	gsap.to(caseHeroImage, {
		scrollTrigger: {
			trigger: caseHero,
			start: 'top 30%',
			scrub: 0.5, // was 0.5
			// markers: true,
		},
		y: -96,
		// scale: 1.25,
		// duration: 1,
	});
}

// Change background color on scroll
if (document.getElementById('case-page')) {

	// Selecteer het container-element
	const container = document.querySelector('.case-intro');

	// Selecteer het element met de klasse .case-detail
	const caseDetailElement = document.querySelector('.case-detail');

	// Haal het id-attribuut op
	const id = caseDetailElement.id;

	// Selecteer het child element met de klasse .case-intro binnen het element met de id
	const caseIntroElement = document.querySelector(`#${id} .case-intro`);

	// Haal de achtergrondkleur op van .case-intro
	const caseColor = window.getComputedStyle(caseIntroElement).backgroundColor;

	// Maak een GSAP-timeline aan voor de animatie
	const timeline = gsap.timeline({
		scrollTrigger: {
			trigger: container,
			start: "bottom top",
			endTrigger: "#case-page",
			end: "+=500",
			scrub: true, // Vloeiende overgang tijdens scrollen
		}
	});

	// Voeg een kleuranimatie toe aan de timeline
	timeline.from('body', {
		backgroundColor: caseColor, // Pas de gewenste hex-kleur hier aan
		duration: 1, // Duur van de animatie in seconden
	});

	// // Optioneel: voeg een ScrollTrigger aan voor het container-element
	// ScrollTrigger.create({
	//   trigger: container,
	//   start: "top top",
	//   end: "center bottom",
	//   scrub: true, // Zorg ervoor dat de trigger overeenkomt met de timeline
	//   animation: timeline,
	// });

}


// Selecteer de afbeelding
const caseHeroImage = document.querySelector('.case-hero-image img');

if (caseHeroImage) {
	gsap.set(caseHeroImage, { scale: 0.75 });
	gsap.to(caseHeroImage, {
		scrollTrigger: {
			trigger: caseHeroImage,
			start: "center bottom", // Begin animatie wanneer de onderkant van de afbeelding de onderkant van het scherm raakt
			end: "bottom bottom", // Eindig animatie wanneer de bovenkant van de afbeelding de bovenkant van het scherm raakt
			scrub: true, // Vloeiende overgang tijdens scrollen
		},
		scale: 1
	});
}

// Select the 'info' div
const infoSection = document.querySelector('.mobile-tree');
const infoDiv = document.querySelector('.info');
const imageDiv = document.querySelector('.images');

// Check if 'imageDiv' exists before creating a ScrollTrigger
if (imageDiv) {
	// Add a ScrollTrigger to pin the 'info' div
	ScrollTrigger.create({
		trigger: infoSection,
		start: "top top", // Start the trigger when the top of 'info' reaches the top of the screen
		endTrigger: imageDiv, // End the trigger when the 'content' div is reached
		end: "+=" + imageDiv.offsetHeight, // End the trigger when the top of 'info' reaches the top of 'content'
		pin: infoDiv, // Pin the 'info' div to the top of the screen
		scrub: true, // Smooth transition during scrolling
	});
}

// script.js
const images = [
	'images/cb-visual-1.jpg',
	'images/cb-visual-2.jpg',
	'images/cb-visual-3.jpg',
	'images/cb-visual-4.jpg',
	'images/cb-visual-5.jpg',
	'images/cb-visual-6.jpg',
];

let currentIndex = 0;

function showNextImage() {
	const imgElement = document.getElementById('randomImage');
	currentIndex = (currentIndex + 1) % images.length; // Verhoog de index en reset bij het einde van de array
	imgElement.src = images[currentIndex]; // Stel de nieuwe afbeelding in
}

// Elke 2 seconden wisselen van afbeelding
// setInterval(showNextImage, 300);

// Start met de eerste afbeelding
// window.onload = function() {
//   const imgElement = document.getElementById('randomImage');
//   imgElement.src = images[0];
// };


// ScrollTrigger instellen voor het snappen van slides
gsap.utils.toArray(".slide").forEach((slide, i) => {
	ScrollTrigger.create({
		trigger: slide,
		start: "top top",
		snap: {
			snapTo: 1, // Snapt naar het dichtstbijzijnde punt (de volgende slide)
			duration: 0.2, // De tijd in seconden voor de snap-animatie (lager is sneller)
			ease: "none", // De easing van de animatie
		},
		markers: false, // markers weergeven voor debugging (verwijder dit in productie)
		scrub: false, // dit zorgt voor een vloeiende animatie bij het scrollen
	});
});


// Voorbeeld: Verberg desktopnavigatie en toon mobiel menu
const mobileMenu = document.querySelector('.navigation');
const mobileMenuToggler = document.querySelector('.menu-toggler');

if (mobileMenuToggler) {
	mobileMenuToggler.addEventListener('click', () => {
		mobileMenuToggler.classList.toggle('open');
		mobileMenu.classList.toggle('open'); // Toggle de mobiele menu-weergave
		document.body.classList.toggle('no-scroll');
	});
}