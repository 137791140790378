// helperController.js
console.log('init helperController');

// Gebruik de functie om te controleren of het een mobiel apparaat is of niet
let isMobile = checkDevice();

function checkDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

function updateIsMobile() {
    isMobile = checkDevice();

    if (isMobile) {
        console.log('mobile');
    } else {
        console.log('desktop');
    }
}

window.addEventListener('resize', updateIsMobile);

export { isMobile };