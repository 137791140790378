// cursorHandler.js
// Handles the custom mouse cursor using some magic

// Import scripts
import { gsap } from "gsap";
import { isMobile } from "./helperController";


// Declare variables
var cursorDOM = document.querySelector('.cursor');
var linkDOMs = Array.from(document.querySelectorAll('a'));
var statusIconDOM = document.querySelector('.mode-icon');
var videoDOMs = document.querySelectorAll('video');

cursorDOM.style.opacity = '0'; // hide cursor

// Display custom cursor only on desktop devices
if (isMobile) {
  // true for mobile device
  document.body.classList.remove('use-cursor');
  document.removeEventListener('mousemove', onMouseMove);
} else {
  // false for not mobile device
  document.body.classList.add('use-cursor');
  document.addEventListener('mousemove', onMouseMove);

  // interact with links and selected elements
  var interactElements = [...linkDOMs, ...videoDOMs, statusIconDOM];
  interactElements.forEach((target) => {
    if (target) { // Controleer of target niet null of undefined is
      target.addEventListener("mouseenter", () => onMouseEnter(target));
      target.addEventListener("mouseleave", () => onMouseLeave(target));
    }
  });
}

// Event listener on mouse enter
function onMouseEnter(target) {
  if (target.parentElement.classList.contains('video-player')) {
    cursorDOM.classList.add('grow--alt');
  } else {
    cursorDOM.classList.add('grow');
  }
}

// Event listener on mouse move
function onMouseMove(e) {
  // track cursor position (X and Y)
  let mouseX = e.clientX;
  let mouseY = e.clientY;

  gsap.to(cursorDOM, {
    x: mouseX,
    y: mouseY,
    duration: 0.5,
  });

  cursorDOM.style.opacity = '1'; // Visibility
}

// Event listener on mouse leave
function onMouseLeave(target) {
  if (target.parentElement.classList.contains('video-player')) {
    cursorDOM.classList.remove('grow--alt');
  } else {
    cursorDOM.classList.remove('grow');
  }
}


// Magnetic social icons on mouse enter
const icons = document.querySelectorAll('.list--horizontal li.social');
const distanceRadius = 50;
const sensitivity = 1;

icons.forEach(icon => {
  icon.addEventListener('mouseenter', () => {
    gsap.to(icon, {
      x: 0, // Set the position to the original point
      y: 0,
      duration: 0.3,
    });
  });

  icon.addEventListener('mousemove', (e) => {
    const mouseX = e.clientX;
    const mouseY = e.clientY;
    const iconX = icon.getBoundingClientRect().left + icon.offsetWidth / 2;
    const iconY = icon.getBoundingClientRect().top + icon.offsetHeight / 2;
    const dx = mouseX - iconX;
    const dy = mouseY - iconY;
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance < distanceRadius) { // Set the radius within which the animation is active
      gsap.to(icon, {
        x: dx / sensitivity, // Adjust the sensitivity of the movement here
        y: dy / sensitivity,
        duration: 0.2,
      });
    }
  });

  icon.addEventListener('mouseleave', () => {
    gsap.to(icon, {
      x: 0, // Go back to the original position
      y: 0,
      duration: 0.3,
    });
  });
});
